<template>
  <div :class="`main-box-container p-1 p-md-3 ${second_position ? 'second-position' : 'first-position'}`">

    <b-avatar-group size="60px" class="mb-2">
      <b-avatar :src="require(`@/assets/images/avatars/ava${i}.png`)" v-for="i in 3" :key="i"></b-avatar>
      </b-avatar-group>
    <h1>{{$t('search.foundResults')}} {{ total_count }} {{ $t('search.foundResultsMatching') }}</h1>
    <p class="avenir-medium">{{ $t('search.garanteeFound') }}</p>
    <b-button class="blue-button" variant="blue-button" @click.once="$router.push({ name: 'pages-account-setting', params: { section: 'membership' } })">
      <feather-icon icon="LockIcon"></feather-icon> {{ $t('search.unlockResults') }}
    </b-button>
  </div>
</template>

<script>
import { 
  BAvatar,
  BAvatarGroup,
  BButton,
} from 'bootstrap-vue';

export default {
  name: 'boxSearchFree',
  components: {
    BAvatar,
    BAvatarGroup,
    BButton,
  },
  props: {
    total_count: {
      type: String,
      default: '0'
    },
    second_position: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box-container {
  background-color: white;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid rgb(223, 223, 223);
  width: 80vw;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.first-position {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.second-position {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>